import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';

const permissions = Permissions.values;

export default [
  {
    path: '/ui-epidemiology',
    permissionRequired: permissions.uiEpidemiologyRead,
    icon: <DashboardIcon />,
    label: 'Burden of CLL',
    sectionHead: true,
    // label: i18n('entities.uiEpidemiology.menu'),
    subMenus: [
      {
        path: '/ui-epidemiology',
        permissionRequired: permissions.uiEpidemiologyRead,
        icon: null,
        label: 'Summary',
        // label: i18n('entities.uiEpidemiology.menu'),
      },
      {
        path: '/incidence',
        permissionRequired: permissions.incidenceRead,
        icon: null,
        label: i18n('entities.incidence.menu'),
      },
      {
        path: '/survival',
        permissionRequired: permissions.survivalRead,
        icon: null,
        label: i18n('entities.survival.menu'),
      },
      {
        path: '/prognostic-characteristics',
        permissionRequired:
          permissions.prognosticCharacteristicsRead,
        icon: null,
        label: 'Prognostic Characteristics',
      },

      {
        path: '/secondary-cancers',
        permissionRequired:
          permissions.secondaryCancersRead,
        icon: null,
        label: 'Secondary Cancers',
      },

      {
        path: '/complications',
        permissionRequired: permissions.complicationsRead,
        icon: null,
        label: i18n('entities.complications.menu'),
      },

      {
        path: '/economic-burden',
        permissionRequired: permissions.economicBurdenRead,
        icon: null,
        label: 'Economic Burden',
      },

      {
        path: '/patient-burden',
        permissionRequired: permissions.patientBurdenRead,
        icon: null,
        label: 'Patient Burden',
      },
    ],
  },
  {
    path: '/real-world-treatment-outcomes',
    permissionRequired: null,
    icon: <DashboardIcon />,
    label: i18n('Real World Treatment Outcomes'),
    sectionHead: true,
    subMenus: [
      {
        path: '/real-world-treatment-outcomes',
        permissionRequired:
          permissions.realWorldTreatmentOutcomesRead,
        icon: null,
        label: i18n('Summary'),
      },
      {
        path: '/real-world-outcomes',
        permissionRequired:
          permissions.realWorldOutcomesRead,
        icon: null,
        label: i18n('Real World Outcomes'),
      },

      {
        path: '/real-world-treatment-patterns',
        permissionRequired:
          permissions.realWorldTreatmentPatternsRead,
        icon: null,
        label: i18n('Real-World Treatment Patterns'),
      },
      {
        path: '/comparative-effectiveness',
        permissionRequired:
          permissions.comparativeEffectivenessRead,
        icon: null,
        label: i18n('Comparative Effectiveness'),
      },
    ],
  },
  {
    path: '/ui-costs-hru',
    permissionRequired: permissions.uiCostsHruRead,
    icon: <DashboardIcon />,
    label: 'Economic Data of Treatments',
    sectionHead: true,
    subMenus: [
      {
        path: '/ui-costs-hru',
        permissionRequired:
          permissions.realWorldTreatmentOutcomesRead,
        icon: null,
        label: i18n('Summary'),
      },

      {
        path: '/costs',
        permissionRequired: permissions.costsRead,
        icon: null,
        label: i18n('entities.costs.menu'),
      },

      {
        path: '/resource-utilization',
        permissionRequired:
          permissions.resourceUtilizationRead,
        icon: null,
        label: i18n('entities.resourceUtilization.menu'),
      },

      {
        path: '/models',
        permissionRequired: permissions.modelsRead,
        icon: null,
        label: i18n('entities.models.menu'),
      },
    ],
  },
  {
    path: '/search-strategy/burden-of-cll',
    permissionRequired: permissions.uiCostsHruRead,
    icon: <DashboardIcon />,
    label: 'Search Strategy:',
    sectionHead: true,
    subMenus: [
      {
        path: '/search-strategy/burden-of-cll',
        permissionRequired:
          permissions.realWorldTreatmentOutcomesRead,
        icon: null,
        label: i18n('Burden of CLL'),
      },

      {
        path: '/search-strategy/real-world-outcomes',
        permissionRequired:
          permissions.searchStrategyRwoRead,
        icon: null,
        label: i18n('Real-World Outcomes'),
      },
      //economic-data
      {
        path: '/search-strategy/economic-data',
        permissionRequired:
          permissions.searchStrategyRwoRead,
        icon: null,
        label: i18n('Economic Data of Treatments'),
      },
    ],
  },

  {
    path: '/ui-references',
    permissionRequired: permissions.uiReferencesRead,
    icon: <DashboardIcon />,
    label: 'References',
  },

  // {
  //   path: '/patient',
  //   permissionRequired: permissions.patientRead,
  //   icon: null,
  //   label: i18n('entities.patient.menu'),
  // },

  // {
  //   path: '/ui-comparative-effectiveness',
  //   permissionRequired: permissions.uiComparativeEffectivenessRead,
  //   icon: null,
  //   label: i18n('entities.uiComparativeEffectiveness.menu'),
  // },
].filter(Boolean);
