import Permissions from 'src/security/permissions';
//import config from 'src/config';
const permissions = Permissions.values;
const privateRoutes = [
  {
    path: '/nullroute',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },
];

const publicRoutes = [
  {
    path: '/t/:tenant',
    loader: () =>
      import('src/view/uiEpidemiology/list/UiEpidemiologyListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },
  {
    path: '/incidence',
    loader: () =>
      import('src/view/incidence/list/IncidenceListPage'),
    permissionRequired: permissions.incidenceRead,
    exact: true,
  },
  {
    path: '/prognostic-characteristics',
    loader: () =>
      import(
        'src/view/prognosticCharacteristics/list/PrognosticCharacteristicsListPage'
      ),
    permissionRequired:
      permissions.prognosticCharacteristicsRead,
    exact: true,
  },
  {
    path: '/ui-epidemiology',
    loader: () =>
      import(
        'src/view/uiEpidemiology/list/UiEpidemiologyListPage'
      ),
    permissionRequired: permissions.uiEpidemiologyRead,
    exact: true,
  },
  {
    path: '/costs',
    loader: () =>
      import('src/view/costs/list/CostsListPage'),
    permissionRequired: permissions.costsRead,
    exact: true,
  },
  {
    path: '/resource-utilization',
    loader: () =>
      import(
        'src/view/resourceUtilization/list/ResourceUtilizationListPage'
      ),
    permissionRequired: permissions.resourceUtilizationRead,
    exact: true,
  },
  {
    path: '/models',
    loader: () =>
      import('src/view/models/list/ModelsListPage'),
    permissionRequired: permissions.modelsRead,
    exact: true,
  },
  {
    path: '/economic',
    loader: () =>
      import('src/view/economic/list/EconomicListPage'),
    permissionRequired: permissions.economicRead,
    exact: true,
  },
  {
    path: '/patient',
    loader: () =>
      import('src/view/patient/list/PatientListPage'),
    permissionRequired: permissions.patientRead,
    exact: true,
  },

  {
    path: '/secondary-cancers',
    loader: () =>
      import(
        'src/view/secondaryCancers/list/SecondaryCancersListPage'
      ),
    permissionRequired: permissions.secondaryCancersRead,
    exact: true,
  },

  {
    path: '/complications',
    loader: () =>
      import(
        'src/view/complications/list/ComplicationsListPage'
      ),
    permissionRequired: permissions.complicationsRead,
    exact: true,
  },

  {
    path: '/economic-burden',
    loader: () =>
      import(
        'src/view/economicBurden/list/EconomicBurdenListPage'
      ),
    permissionRequired: permissions.economicBurdenRead,
    exact: true,
  },

  {
    path: '/patient-burden',
    loader: () =>
      import(
        'src/view/patientBurden/list/PatientBurdenListPage'
      ),
    permissionRequired: permissions.patientBurdenRead,
    exact: true,
  },

  {
    path: '/real-world-treatment-outcomes',
    loader: () =>
      import(
        'src/view/realWorldTreatmentOutcomes/list/RealWorldTreatmentOutcomesListPage'
      ),
    permissionRequired:
      permissions.realWorldTreatmentOutcomesRead,
    exact: true,
  },

  // {
  //   path: '/ui-comparative-effectiveness',
  //   loader: () =>
  //     import(
  //       'src/view/uiComparativeEffectiveness/list/UiComparativeEffectivenessListPage'
  //     ),
  //   permissionRequired:
  //     permissions.uiComparativeEffectivenessRead,
  //   exact: true,
  // },

  {
    path: '/real-world-outcomes',
    loader: () =>
      import(
        'src/view/realWorldOutcomes/list/RealWorldOutcomesListPage'
      ),
    permissionRequired: permissions.realWorldOutcomesRead,
    exact: true,
  },

  {
    path: '/real-world-treatment-patterns',
    loader: () =>
      import(
        'src/view/realWorldTreatmentPatterns/list/RealWorldTreatmentPatternsListPage'
      ),
    permissionRequired:
      permissions.realWorldTreatmentPatternsRead,
    exact: true,
  },

  {
    path: '/ui-costs-hru',
    loader: () =>
      import('src/view/uiCostsHru/list/UiCostsHruListPage'),
    permissionRequired: permissions.uiCostsHruRead,
    exact: true,
  },

  {
    path: '/real-world-treatment-outcomes2',
    loader: () =>
      import(
        'src/view/realWorldTreatmentOutcomes2/list/RealWorldTreatmentOutcomes2ListPage'
      ),
    permissionRequired:
      permissions.realWorldTreatmentOutcomes2Read,
    exact: true,
  },

  {
    path: '/search-strategy/:search_strategy_name',
    loader: () =>
      import(
        'src/view/searchStrategy/list/SearchStrategyListPage'
      ),
    permissionRequired: permissions.searchStrategyRead,
    exact: false,
  },

  {
    path: '/comparative-effectiveness',
    loader: () =>
      import(
        'src/view/comparativeEffectiveness/list/ComparativeEffectivenessListPage'
      ),
    permissionRequired:
      permissions.comparativeEffectivenessRead,
    exact: true,
  },

  {
    path: '/search-data-economic',
    loader: () =>
      import(
        'src/view/searchDataEconomic/list/SearchDataEconomicListPage'
      ),
    permissionRequired: permissions.searchDataEconomicRead,
    exact: true,
  },

  {
    path: '/ui-references',
    loader: () =>
      import(
        'src/view/uiReferences/list/UiReferencesListPage'
      ),
    permissionRequired: permissions.uiReferencesRead,
    exact: true,
  },

  {
    path: '/survival',
    loader: () =>
      import('src/view/survival/list/SurvivalListPage'),
    permissionRequired: permissions.survivalRead,
    exact: true,
  },
].filter(Boolean);

const emptyTenantRoutes = [].filter(Boolean);

const simpleRoutes = [
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  simpleRoutes,
};
